import { TEXT_INPUT, TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const assetTagsFields = {
    settings_inventory_asset_tag_barcode: {
      type: TEXT_INPUT,
      label: 'Name',
      placeholder: 'Type...',
    },
    settings_inventory_asset_tag_template: {
      type: TEXTAREA_INPUT,
      label: 'Template',
      rules: 'required',
      options: {
        rows: 6,
      },
    },
  }
  return {
    assetTagsFields,
  }
}
