<template>
  <div>
    <asset-tags-form
      v-if="!loading"
      ref="asset-tags-form"
    />
    <div v-else-if="loading">
      <tags-skeleton />
    </div>
  </div>

</template>
<script>
import {
} from 'bootstrap-vue'
import TagsSkeleton from '@/views/settings/inventory/asset-tags/components/TagsSkeleton.vue'
import config from '../assetTagsConfig'
import AssetTagsForm from '../components/AssetTagsForm.vue'

export default {
  name: 'AssetTagsUpdate',
  components: {
    TagsSkeleton,
    AssetTagsForm,
  },
  data() {
    return {
      error: false,
      loading: false,
    }
  },
  computed: {
    assetTags() {
      return this.$store.state[this.MODULE_NAME].assetTagsForm
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch(`${this.MODULE_NAME}/getAssetTags`).then(res => {
      const { data } = res
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, data)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_ASSET_TAGS_FORM_CLONE`, data)
    }).catch(err => {
      console.log(err.response?.data)
    }).finally(() => {
      this.loading = false
    })
  },
  setup() {
    const MODULE_NAME = 'asset-tags'
    const MODULE_NAME_CLONE = 'cloneData'
    const { assetTagsFields } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      assetTagsFields,
    }
  },
}
</script>
