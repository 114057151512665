<template>
  <div v-if="!loading">
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <error-alert
      :fields="assetTagsFields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="assetTagsCreateVal"
      >
        <h1 class="font-weight-bolder font-medium-4">
          Barcode
        </h1>
        <b-row>
          <!--          <b-col-->
          <!--            v-for="field in ['settings_inventory_asset_tag_barcode']"-->
          <!--            :key="field"-->
          <!--            md="6"-->
          <!--          >-->
          <!--            <component-->
          <!--              :is="assetTagsFields[field].type"-->
          <!--              v-model="assetTags[field]"-->
          <!--              v-bind="getProps(field)"-->
          <!--              :is-editable="isEdit"-->
          <!--            />-->
          <!--          </b-col>-->
          <b-col
            v-for="field in ['settings_inventory_asset_tag_template' ]"
            :key="field"
            class="mt-1"
            md="12"
          >
            <component
              :is="assetTagsFields[field].type"
              v-model="assetTags[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="cancel"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { required } from '@/libs/validations/validations'
import { scrollToError } from '@core/utils/utils'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BRow, BCol, BForm, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import store from '@/store'
import config from '../assetTagsConfig'

export default {
  name: 'AssetTagsForm',
  components: {
    BButton,
    BRow,
    BCol,
    BForm,
    ErrorAlert,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,

  },

  data() {
    return {
      required,
      error: {},
      loading: false,
      onSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    assetTags() {
      return this.$store.state[this.MODULE_NAME].assetTagsForm
    },
    assetTagsClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].assetTagsFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.assetTags) !== JSON.stringify(this.assetTagsClone)
    },
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.assetTagsCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.assetTags
            const defaultAssetTags = this.mappingFields(Object.keys(this.assetTagsFields), data)
            this.sendNotification(this, { ...defaultAssetTags }, `${this.MODULE_NAME}/updateAssetTags`)
              .then(() => {
                this.$router.replace({
                  name: 'inventory-asset-condition-list',
                })
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.assetTagsCreateVal)
          }
        })
    },
    clear() {
      this.$refs.assetTagsCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    },
    cancel() {
      this.clear()
      this.$router.push({ name: 'inventory-asset-condition-list' })
    },
    loader() {
      this.$refs.assetTagsCreateVal.reset()
      // const smth = JSON.parse(this.assetTagsClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, this.assetTagsClone)
    },
    getProps(fieldName) {
      const dataOF = {
        field: {
          ...this.assetTagsFields[fieldName],
        },
        name: fieldName,
      }
      // if (fieldName === 'state_id') {
      //   data.parentValue = this.brand.country_id?.id
      // }
      return dataOF
    },
  },
  setup() {
    const MODULE_NAME = 'asset-tags'
    const MODULE_NAME_CLONE = 'cloneData'
    const { assetTagsFields } = config()

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      assetTagsFields,
    }
  },
}
</script>
